import React from "react"
import { Helmet } from "react-helmet"

import Header from "src/components/headers/header"
import Footer from "src/components/footers/footer"
import Form from "src/components/formulaires/form-contact-partenaire"


export default function index() {

  return (
    <div className="bg-white">
      <Helmet>
        <title>Contact partenaire Géotechnique Maison.fr</title>
        <meta name="description" content="Contact partenaire Géotechnique Maison.fr" />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
      </header>

      <main className="relative">
        <div className="relative bg-white">
          <div className="relative max-w-7xl mx-auto py-8 px-4">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
              Contact partenaire Géotechnique Maison.fr
              </span>
            </h1>
            <div className="hidden lg:block">
              <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
              Nous vous répondrons dans les plus brefs délais.
              <br />
              Vous êtes intéressé pour devenir l’un des partenaires géotechniciens de notre site. C’est une super nouvelle, nous en sommes ravis. 
              <br />
              Faîtes-nous part de votre candidature en complétant le formulaire ci-dessous. 
              </p>
            </div>

          </div>
        </div>

        <Form />

      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  )
}